<template lang="pug">
  div.members
    router-link.back_arrow(:to="{ name: 'admin_home' }")
    button.disp_layout(type="button" @click="changeZoom")
      IconBase(width="25" height="25" icon-name="layouts")
        IconTh(v-if="isZoom")
        IconThLarge(v-else)

    ul.department_list
      li.department_item(v-for="departmentGroup in groupByDepartment(users)" :key="Object.keys(departmentGroup).shift()")
        h2(:id="'department_' + Object.keys(departmentGroup).shift()") {{ Object.keys(departmentGroup).shift() | convertMasterName('departments', master) }}
          small （{{ departmentGroup[Object.keys(departmentGroup).shift()].length }}名）
        ul.member_list(:class="{unZoom: !isZoom}")
          li.member(v-for="user in departmentGroup[Object.keys(departmentGroup).shift()]" :key="user.id")
            MemberItem(:account="user")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MemberItem from '@/components/MemberItem'
import IconBase from '@/components/IconBase.vue'
import IconTh from '@/components/icons/IconTh.vue'
import IconThLarge from '@/components/icons/IconThLarge.vue'
export default {
  name: 'MembersView',
  metaInfo: {
    title: '【管理】社員一覧画面',
  },
  data() {
    return {
      isZoom: null,
    }
  },
  components: {
    MemberItem,
    IconBase,
    IconTh,
    IconThLarge,
  },
  computed: {
    ...mapState('authModule', ['account']),
    ...mapState('usersModule', ['users']),
    ...mapState('masterModule', ['master']),
  },
  created() {
    this.isZoom =
      typeof this.account.isZoom == 'undefined' ? true : this.account.isZoom
  },
  methods: {
    ...mapActions('authModule', ['updateAccount']),
    async changeZoom() {
      this.isZoom = !this.isZoom
      await this.updateAccount({
        isZoom: this.isZoom,
      })
    },
    groupByDepartment(users) {
      const array = {}
      Object.keys(users).forEach((id) => {
        const account = users[id]
        if (!(account.departmentId in array)) {
          array[account.departmentId] = []
        }
        array[account.departmentId].push(account)
      })
      Object.keys(array).forEach((departmentId) => {
        array[departmentId].sort(
          (a, b) =>
            this.master.positions[a.positionId].sortId -
            this.master.positions[b.positionId].sortId,
        )
      })
      let sortDepartment = Object.keys(array).sort(
        (a, b) =>
          this.master.departments[a].sortId - this.master.departments[b].sortId,
      )
      let resutl = []
      sortDepartment.forEach((departmentId) => {
        resutl.push({ [departmentId]: array[departmentId] })
      })
      return resutl
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.members {
  .back_arrow {
    content: '';
    width: 25px;
    height: 25px;
    position: fixed;
    line-height: 1;
    top: 10px;
    left: 15px;
    padding: 0;
    font-size: 25px;
    color: #666;
    z-index: 20;
    cursor: pointer;
    animation: fadeIn 3s ease 0s 1 normal;
    -webkit-animation: fadeIn 3s ease 0s 1 normal;
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      content: '';
      width: 12px;
      height: 12px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: translateY(-50%) rotate(-135deg);
    }
  }
  .disp_layout {
    position: fixed;
    line-height: 1;
    top: 10px;
    right: 13px;
    padding: 0;
    font-size: 25px;
    color: #666;
    z-index: 20;
    cursor: pointer;
    animation: fadeIn 3s ease 0s 1 normal;
    -webkit-animation: fadeIn 3s ease 0s 1 normal;
  }
  @include noZoom {
    .disp_layout {
      display: none;
    }
  }
}
.department_list {
  margin: 10px 0 0;
  .department_item {
    h2 {
      position: sticky;
      top: 0;
      z-index: 15;
      background: #fff;
      padding: 15px 5px;
      text-align: center;
      font-weight: bold;
      @include iphone5 {
        font-size: 13px;
      }
      small {
        font-size: 13px;
      }
    }
    .member_list {
      display: flex;
      flex-wrap: wrap;
      margin: 10px auto 0;
      padding: 0 8px;
      @include pc {
        padding: 0 15px;
      }
      max-width: 1020px;
      .member {
        @include noZoom {
          width: calc(100% / 3 - 16px) !important;
        }
        @include tab {
          width: calc(100% / 4 - 16px) !important;
        }
        @include pc {
          width: calc(100% / 5 - 16px) !important;
        }
        @include max {
          width: calc(100% / 6 - 16px) !important;
        }
        width: calc(50% - 16px);
        padding: 0 8px;
        margin: 10px 0 25px;
        transition: all 0.4s;
      }
      &.unZoom {
        .member {
          width: calc(100% / 3 - 16px);
        }
      }
    }
  }
}
</style>
<style>
.unZoom .member_item a .img_wrap .position {
  font-size: 10px;
  bottom: -7px;
}
</style>
